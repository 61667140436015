const en = {
  somethingWentWrong: "Sorry Something Went Wrong Please Try Again Later",

  login: "Login",
  loginId: "Login ID",
  password: "Password",

  users: "Users",
  name: "Name",
  phoneNumber: "Phone Number",
  userId: "User ID",
  email: "Email",
  idNumber: "Number ID",
  submit: "Submit",
  createUser: "Create User",
  registeredAt: "Registered At",
  days: "Days",
  gym: "Gym",
  swim: "Swim",

  dailyReport: "Daily Report",
  advancedDailyReport: "Advance Daily Report",
  orders: "Orders",
  admin: "Admin",
  logout: "Logout",

  admins: "Admins",
  center: "Center",
  packages: "Packages",

  id: "ID",
  createdAt: "Created At",
  type: "Type",
  features: "Features",

  duration: "Duration",
  price: "Price",
  discount: "Discount",
  englishName: "English Name",
  kurdishName: "Kurdish Name",
  arabicName: "Arabic Name",
  showType: "Show Type",
  firstTime: "First Time",
  afterFirstTime: "After First Time",
  allTime: "All Time",

  owner: "Owner",
  manager: "Manager",
  trainer: "Trainer",
  reception: "Reception",

  distributionWithTrainer: "Distribution With Trainer",
  notDistributionWithTrainer: "Not Distribution With Trainer",

  divideBenefit: "Divide Benefit",
  requestCourse: "Courses",
  cafeteria: "Cafeteria",
  cafeteriaBenefit: "Cafeteria Benefit",
  expenses: "Expenses",
  cafeteriaExpenses: "Cafeteria Expenses",
  totalCafeteriaExpenses: 'Total Cafeteria Expenses',
  gymExpenses: "Gym Expenses",
  date: "Date",

  endDate: "End Date",

  supplements: "Supplements",

  newUser: "New User",
  renewSubscription: "Renew Subscription",
  membershipBenefit: "Membership Benefit",
  reportSummary: "Summary of Report",
  advancedReportSummary: "Advanced Report Summary",
  revenue: "Revenue",
  profit: "Profit",
  membershipSummary: "Membership Summary",
  financeSummary: "Finance Summary",
  main: "Main",

  numberOrder: "Order Num.",
  dashboard: "Dashboard",

  memberships: "Memberships",
  activeUser: "Active User",
  expiredUser: "Expired User",
  startDate: "Start Date",
  start: "Start",
  end: "End",
  day: "Day",
  username: "Username",
  ticketNumber: "Ticket Number",

  offerType: "Offer Type",
  receptionDivideBenefit: "Distribution amount with receipt",
  normal: "Normal",
  offer: "Offer",

  delete: "Delete",

  undefined: "Undefined",
  male: "Male",
  female: "Female",
  gender: "Gender",

  openingTheDoors: "Opening the doors",
  door: "Door",
  reason: "Reason",
  employeeComing: "Employee Coming",

  wholesalePrice: "Wholesale price",
  minimumSalePrice: "Minimum Sale Price",
  buyPrice: "Buy Price",

  balance: "Balance",

  numberOfGuestUsers: "Number Of Guest Users",
  sellPrice: "Sell Price",
  realPrice: "Real Price",

  numberOfProductInStore: "Number of products in stock",

  buy: "Buy",
  returnOfPurchase: "Return of Purchase",
  image: "Image",
  select: "Select",
  sellId: "Sell ID",
  note: "Note",
  customers: "Customers",
  debt: "Debt",
  repaymentOfDebt: "Repayment Of Debt",
  sell: "Sell",
  freeWallet: "Free Wallet",
  money: "Money",
  payedPrice: "Payed Price",
  totalPrice: "Total Price",
  productNumber: "Product Number",
  totalPreviousDebts: "Total previous debts",
  totalDebts: "Total Debts",
  quantity: "Quantity",
  qty: 'Qty.',
  print: "Print",
  equipments: "Equipments",
  shippingCars: "Shipping Cars",
  brands: "Brands",
  totalPayedPrice: "Total Payed Price",
  remainingDebts: "Remaining Debts",
  brand: "Brand",
  code: "Code",
  salary: "Salary",
  stock: "Stock",
  cleaner: "Cleaner",
  accountant: "Accountant",
  proteinSeller: "Protein Seller",
  socialMedia: "Social Media",
  technical: "Technical",
  expense: "Expense",
  cafeteriaGuestUser: "Cafeteria Guest User",
  totalSalary: "Total Salary",
  garage: "Garage",
  supplementExpenses: "Supplements Expenses",
  bigExpenses: "Big Expenses",
  package: "Package",
  insertLogoToImage: "Insert Logo To Image",
  proteinManager: "Protein Manager",
  equipmentManager: "Equipment Manager",
  stockBenefitPrice: "قازانجی کۆگا (نرخی تاک)",
  stockBenefitWholePrice: "قازانجی کۆگا (نرخی کۆ)",
  stockBenefitBuyPrice: "کۆی کۆگا (نرخی کڕین)",

  benefitPrice: "قازانج (نرخی تاک)",
  BenefitWholePrice: "قازانج (نرخی کۆ)",

  live: "Live",
  purchasesHistory: "History of Purchases",
  balancesHistory: "History of Balances",
  usersFeedback: "Feedbacks",
  starRate: "Stars",
  feedback: "Feedback",
  newSubscribers: "New Subscribers",
  renewalOfSubscriptions: "Renewal of Subscriptions",
  dollarPrice: "Dollar Price",
  proteinStock: 'Protein Stock',
  receiptsOfPayment: "Receipts Of Payment",
  retailPrice: 'Retail Price',
  subs: 'Subs',
  subject: 'Subject',
  part: 'Part',
  recipients: 'Recipients',
  receipts: 'Receipts',
  customs: 'Customs',
  carNumber: 'Car Number',
  cost: 'Cost',
  expense_1: 'Expense',
  creatingTransition: 'Creating a Transition',
  centers: 'Centers',
  administration: 'Administration',
  brandImage: 'Brand Image',
  categories: 'Categories',
  jobOpportunities: 'Job Opportunities',
  cvs: 'CVs',
  location: 'Location',
  position: 'Position',
  revenueByBenefitPrice: 'Revenue By (Sell Price - Buy Price)',
  revenueByItems: 'Revenue by product sold',
  revenueByBuyPrice: 'Revenue by product sold',
  profitWithoutExpenses: 'Profit without expense',
  publicDebt: 'Public Debt',
  ratio: 'Ratio',
  profitAtRetailPrice: 'Profit at retail price',
  profitAtWholeSalePrice: 'Profit At Wholesale Price',
  gymReception: 'Gym Reception',
  cafeteriaReception: 'Cafeteria Reception',
  gymCoach: 'Gym Coach',
  monthlyReport: 'Monthly Report',
  normalCourseProfit: 'Normal Course Profit',
  VIPCourseProfit: 'PT Course Profit',
  cafeteriaProfit: 'Cafeteria Profit',
  publicProfitAfterMamAzad: 'Public Profit After Mam Azad',
  publicProfit: 'Public Profit',
  coursesReport: 'Courses Report',
  save: 'Save',
  loading: 'Loading',
  equipmentStock: 'Equipment Stock',
  licenses: 'Licenses',
  'attendance-time': 'Attendance Time',
  childrenNannies: 'Children Nannies',
  accountantOfAnotherCompany: 'Accountant Of Another Company',
  asudaCity: 'Asuda City',
  'asudaCity$': 'Asuda City $',
  paroshTowers: 'Parosh Towers',
  'paroshTowers$': 'Parosh Towers $',
  slavaCity: 'Slava City',
  'slavaCity$': 'Slava City $',
  mamAzad: 'Mam Azad',
  'mamAzad$': 'Mam Azad $',
  miniSlavaMen: 'MiniSlava Men',
  'miniSlavaMen$': 'MiniSlava Men $',
  miniSlavaWomen: 'MiniSlava Women',
  'miniSlavaWomen$': 'MiniSlavaWomen $',
  lifeTowers: 'Life Towers',
  'lifeTowers$': 'Life Towers $',
  akaFitnessProfit: '78 Gym Profit',
  akaFitnessProfit$: '78 Gym Profit $',
  finalProfit: 'Final Profit',
  'price-lists': 'Price Lists',
  'select-item': 'Select Item',
  cancel: 'Cancel',
  repaymentOfDebtAkaPower: 'Repayment Of Debt (AkaPower)',
  debtsAkaPower: 'Debts (AkaPower)',
  swimmingItems: 'Swimming Items',
  akaFitnessSupplementsProfit: '78 Gym Supplement Profit',
  asudaSupplementsProfit: 'Asuda Supplement Profit',
  finalPrice: 'Final Price',
  totalRepaymentOfDebt: 'Total Repayment Of Debt',
  totalPayedPriceFromSells: 'Total Payed Price From Sells',
  'revenue(repaymentOfDebt,payedPrice)': 'Revenue (Repayment of Debt, Payed Price)',
  ratioByDebt: 'Ratio By Debt',
  ratioBySupplement: 'Ratio By Supplements',
  ratioByMoney: 'Ratio By Money',
  ratioType: 'Ratio Type',

  gyms: 'Gyms',

  currentMonth: 'Current Month',
  lastMonthByThisTime: 'Last Month By This Time',
  lastMonth: 'Last Month',
  allOfThem: 'All Of Them 💥',
  trainers: 'Trainers',
  thereIsNoDataToDisplay: 'There is no data to display',
  sellSupplements: 'Sell Supplements',
  deleteMembership: 'Delete Memberships',

  'areYouSureYouWantToDeleteThisExpense?': 'Are you sure you want to delete this expense?',
  deletionOfExpenses: 'Deletion of expenses',
  'areYouSureAboutDeleting?': 'Are you sure about deleting?',
  totalPriceOfRegularCourses: 'Total Price Of Regular Courses',
  'totalPriceOfVIPCourses': 'Total price of PT courses',
  numOfNormalCourses: 'Num Of Normal Courses',
  numOfVIPCourses: 'Num Of PT Courses',
  'trainerProfitsFromRegularCourses': 'Trainer profits from regular courses',
  'trainerProfitsFromVIPCourses': 'Trainer profits from PT courses',
  akaFitnessProfitFromRegularCourses: '78 Gym profit from regular courses',
  'akaFitnessProfitsFromVIPCourses': '78 Gym profits from PT courses',
  coachGrossProfit: 'Coach gross profit',
  grossProfitAkaFitness: 'Gross Profit 78 Gym',
  num: 'Num.',
  nameOfTheEmployee: 'Name of the employee',
  fixedSalary: 'Fixed Salary',
  courseRatio: 'Course Ratio',
  supplementRatio: 'Supplement Ratio',
  numOfSupplements: 'Num Of Supplements',
  totalPriceOfSupplements: 'Total Price Of Supplements',
  'trainerProfit15%': 'Trainer Profit 15%',
  theDollarIsGenerallyCalculatedAt: 'The dollar is generally calculated at',
  courseReportSummary: 'Course Report Summary',
  salariesOfEmployees: 'Salaries of employees {{price}}',
  summaryOfTheDietarySupplementReport: 'Summary of the Dietary Supplement Report',
  cafeteriaSalesReportSummary: 'Cafeteria Sales Report Summary',
  totalOfProductsWereSoldAtPriceOfDinars: 'A total of {{qty}} products were sold at a price of {{price}} dinars',
  salaryNotSpecified: 'Salary not specified',
  dateNotSpecified: 'Date not specified',
  salaryByDate: 'Salary by date',

  increasedExpenses: 'Increased expenses',
  updateOfExpenses: 'Update of expenses',
  byPrice: 'by',

  courseWriting: 'Course writing',
  courseUpdate: 'Course update',
  changeOfParticipation: 'Change of participation',

  sellingOfSupplements: 'Selling of supplements',
  updateOfSellingSupplements: 'Update of selling supplements',
  purchaseBalance: 'Purchase balance',
  changeOfBalance: 'Change of Balance',
  openingTheDoor: 'Opening the door',
  becauseOf: 'Because of',
  saleOfCafeteriaProducts: 'Sale of cafeteria products',

  star: 'Star',
  in: 'in',
  for: 'for',
  total: 'Total',
  search: 'Search',

  receptionDivideBenefitIQD: 'Reception divide benefit IQD',

  numOfMemberships: 'Num Of Memberships',
  numOfThreeMonth: 'Num Of Three Month Memberships',
  numOfSixMonth: 'Num Of Six Month Memberships',
  totalPriceOfThreeMonths: 'Total Price Of Three Months',
  totalPriceOfSixMonths: 'Total Price Of Six Months',
  receptionRatioOfThreeMonths: 'Reception Ratio Of Three Months',
  receptionRatioOfSixMonths: 'Reception Ratio Of Six Months',
  akaFitnessDivideBenefitIQD: '78 Gym Divide Benefit IQD',

  receptionReportSummary: 'Reception Report Summary',
  receptionsRatio: 'Receptions Ratio',
  'thereWere{{update}}UpdatesAnd{{new}}NewSubscribersInTheReportGenerated': 'There Were {{update}} Updates And {{new}} New Subscribers In The Report Generated',
  membershipsRatio: 'Memberships Ratio',
  cafeExpense: 'Cafe Expense',
  gymExpense: 'Gym Expense',
  bigExpense: 'Big Expense',

  by: 'By',
  expiredUsers: 'Expired Users',
  totalExpenses: 'Total Expenses',

  requests: 'Requests',
  inProgress: 'In Progress ⛏️',
  pendingRequests: 'Pending Requests',
  status: 'Status',
  pending: 'Pending',
  accepted: 'Accepted',
  rejected: 'Rejected',
  moreDetails: 'More Details',
  request: 'Request',
  answeredRequests: 'Answered Requests',
  data: 'Data',
  viewData: 'View Data',
  captain: 'Captain',
  normalCustomer: 'Normal Customer',
  hallOrAgentCustomer: 'Hall Or Agent Customer',
  partnerAmount: 'Partner Amount',
  pleaseSelectCustomer: 'Please Select Customer',
  supplementsPartner: 'Supplements Partner',
  selectInvoice: 'Select Invoice',
  invoices: 'Invoices',
  notPaid: 'Not Paid',
  'paid(uncompleted)': 'Paid (uncompleted)',
  'paid(completed)': 'Paid (completed)',
  'nutritionalSupplementReportSummary': 'Nutritional Supplement Report Summary',
  'partnerNutritionalSupplementReportSummary': 'Partner Nutritional Supplement Report Summary',
  'revenue(repaymentOfDebt,payedPrice)OfPartnerItems': 'Revenue (Repayment Of Debt, Payed Price) Of Partner Items',
  'profit(repaymentOfDebt,payedPrice)OfPartnerItems': 'Profit (Repayment Of Debt, Payed Price) Of Partner Items',
  partnerBalance: 'Partner Balance',
  'partnerSellDetails': 'On the given date, the products sold have a gross price of {{totalPrice}}, of which {{payedPrice}} was in payed price(cash), {{debt}} was in debt, the profit on product sold (product sold by payed price(cash) - purchase price) is {{profitByBuyPrice}}, and the net profit is ( Profit - General Expense) is {{balance}}',
  supplementName: 'Supplement Name',
  buyReportSummary: 'Buy Report Summary',
  buySupplementDetails: 'Purchases made at a total cost of {{totalPrice}} and purchased {{quantity}} different nutritional supplement, total of {{totalQuantity}} nutritional supplements purchased',
  expenseName: 'Expense Name',
  expenseReportSummary: 'Expense Report Summary',
  expensesReportDetails: `A total of {{numOfExpense}} expenses have been incurred making the total cost of expenses {{totalPrice}} $`,
  customerName: 'Customer Name',
  totalRatio: 'Total Ratio',
  ratioReportSummary: 'Ratio Report Summary',
  ratioReportDetails: 'The total ratio of the given date is {{totalRatio}} $ to {{totalCustomers}} different customers',
  ratioForDebt: 'Ratio By Debt',
  ratioForSupplement: 'Ratio By Supplement',
  ratioForMoney: 'Ratio By Money',
  amountOfRepaymentDebt: 'Amount Of Repayment Debt',
  repaymentOfDebtReportSummary: 'Repayment Of Debt Report Summary',
  'thisReportWillBePreparedSoonAsSoon': 'Because our repayment structure was different in the past, we need to change it and calculate all repayments, so this report will be prepared soon as soon ⚠️',
  'summaryOfTheSoldSupplementsReport': 'Summary of the Sold Supplements Report',
  totalSoldQty: 'Total Sold Qty',
  totalSoldPrice: 'Total Sold Price',
  totalProfitPrice: 'Total Profit Price',
  totalPriceOfCash: 'Total Price Of Cash',
  totalPriceOfDebt: 'Total Price Of Debt',
  totalPriceOfFree: 'Total Price Of Free',
  'totalSoldPrice(cash+debt)': 'Total Sold Price (Cash + Debt)',
  totalPriceOfRatio: 'Total Price Of Ratio',
  'totalProfitPrice(cash)': 'Total Profit Price (Cash)',
  'totalProfitPrice(OnlyCash)': 'Total Profit Price (Only Cash)',
  selectDate: 'Select Date',
  expiryTodayMemberships: 'Expiry Today Memberships',
  freeSubscriptions: 'Free Subscriptions',
  debtsReportSummary: 'Debts Report Summary',
  debtReportDetails: 'The total debt of the given date is {{totalDebt}} $ to {{totalCustomers}} different customers',
  productReturnReportSummary: 'Product Return Report Summary',
  productReturnReportDetails: `A total of {{totalQty}} supplements were returned at a total cost of {{totalPrice}} $`,
  sellSupplementsReportSummary: 'Sell Supplements Report Summary',
  sellSupplementsReportDetails: `A total of {{totalQty}} supplements were sold for {{totalPrice}} $, including $ {{totalDebt}} in loans and $ {{totalPayedPrice}} in cash, The total price of free supplements was {{totalFree}} $`,
  freeSupplementsBy: ' ( Free Supplements By {{totalPrice}} $)',
  totalDebt: 'Total Debt',
  summaryOfTheSoldSupplementsReportDetails: `Revenue at buy price (including free supplements) $ {{totalBenefitPriceWithoutMinus}}, final profit is $ {{totalBenefitPrice}}`,
  totalBuyPrice: 'Total Buy Price',
  totalStockSupplements: 'Total stock supplements',
  totalStockDetails: 'The total stock remaining is {{totalQty}} at a purchase price of {{totalBuyPrice}} $, the amount of {{totalIronmaxQty}} is from Iron Max brand and the amount of {{totalIronmaxPrice}} is $, the amount is {{totalHelltechQty}} of Helltech brand at {{totalHelltechPrice}} $, and the amount of {{totalOtherBrand}} is one of other brands at {{totalOtherBrandPrice}} dollars',
  totalDebtors: 'Total Debtors',
  totalDebtorsDetails: 'Our customers owe a total of {{totalDebt}}, of which {{totalHallAgent}} is 78 Gym Gym and Agent and ${{otherCustomers}} is an other customer',
  'revenue(payedPrice)': 'Revenue (Payed Price)',
  'revenue(repaymentOfDebt)': 'Revenue (Repayment Of Debt)',
  totalRevenue: 'Total Revenue',
  'profit(repaymentOfDebt,payedPrice)': 'Profit (Repayment Of Debt, Payed Price)',
  'buyPriceXtotalSoldQty': 'Buy Price X Total Sold Qty',
  'profit(totalSoldPrice-buyPrice*totalSoldQty': 'Profit (Total Sold Price - Buy Price * Total Sold Qty)',

  totalMembershipsRevenue: 'Total Memberships Revenue',
  totalNormalCoursesRevenue: 'Total Normal Courses Revenue',
  totalPTCoursesRevenue: 'Total PT Courses Revenue',
  totalCafeteriaRevenue: 'Total Cafeteria Revenue',
  PTCourseRatio: 'PT Course Ratio',
  normalCourseRatio: 'Normal Course Ratio',
  'totalOtherRevenue(salon,garage,vipLocker)': 'Total Other Revenue (Salon, Garage, Vip Locker)',
  totalGymExpenses: 'Total Gym Expenses',
  totalBigExpenses: 'Total Big Expenses',
  totalReceptionRatio: 'Total Reception Ratio',
  totalFixedSalary: 'Total Fixed Salary',
  totalMembershipsProfit: 'Total Memberships Profit',
  totalCoursesProfit: 'Total Courses Profit',
  totalCafeteriaProfit: 'Total Cafeteria Profit',
  totalProfit: 'Total Profit',
  totalPartnerProfit: 'Total Partner Profit',
  totalAkaFitnessProfit: 'Total 78 Gym Profit',
  settings: 'Settings',
  beta: 'Beta',
  courses: 'Courses',

  'fromSelectedRangeLastMonth': 'From Selected Range Of Last Month',
  overview: 'Overview',
  totalRevenueAkaPower: 'Total Revenue (AkaPower)',
  totalExpensesAkaPower: 'Total Expenses (AkaPower)',
  totalProfitAkaPower: 'Total Profit (AkaPower)',
  totalProfitAkaNutrition: 'Total Profit (Aka Nutrition)',
  totalProfitAkaFitness: 'Total Profit (78 Gym)',
  totalEmployees: 'Total Employees',
  totalActiveSubscribersAkaFitness: 'Total Active Subscribers (78 Gym)',
  expire: 'Expire',
  new: 'New',
  renew: 'Renew',

  akaFitness: 'Aka Fitness',
  akaNutrition: 'Aka Nutrition',
  akaPower: 'Aka Power',
  akaEquipment: 'Aka Equipment',

  packageName: 'Package Name',
  receptionName: 'Reception Name',
  expiryDate: 'Expiry Date',

  cash: 'Cash',
  vipLocker: 'ViP Locker',
  salon: 'Salon',
  cafeCard: 'Cafe Card',

  totalCourseProfit: 'Total Course Profit',
  totalSupplementProfit: 'Total Supplement Profit',
  activeUsers: 'Active Users',

  'new,renew,expireSubscription': 'New, Renew, Expire Subscription',
  'male&female': 'Male & Female',
  cafeteriaAnalysis: "Cafeteria Analysis",
  totalPriceOfCard: "Total Price Of Card",
  totalPriceOfBalance: "Total Price Of Card",

  calculateSalaries: "Calculate Salaries",
  calculateSalariesDesc: "Salary calculations will be available soon",

  totalSalaries: "Total Salaries",
  directDollarExchangeRateLinkedToTheKurdistanStockExchange: "Direct Dollar Exchange Rate Linked To The Kurdistan Stock Exchange",
  language: 'Language',
  selectTheLanguageOfThePlatform: 'Select the language of the platform',
  profile: 'Profile',
  appearance: 'Appearance',
  other: 'Other',
  next: 'Next',
  previous: 'Previous',
  salaryListOf: 'Salary List Of {{name}}',
  totalOtherRevenueProfit: 'Total Other Revenue Profit',
  singlePrice: 'Single Price',
  nutrition: 'Nutrition',

  columns: 'Columns',
  deleteUserWarning: 'Please be careful when deleting a user because it will delete all the data related to the user and it will not be recoverable',
  "physicalTherapy": "Physical Therapy",
  "physicalTherapyRevenue": "Physical Therapy Revenue",
  "physicalTherapyProfit": "Physical Therapy Profit",
  aFullHourPhysicalTherapySessionForPartBody: "A full hour physical therapy session for part body",
  fullBodyMessageOneHour: "Full body massage for one hour",
  fourPhysicalTherapy: "Four physical therapy sessions or part of the body",
  amountOfPaymentByCustomer: 'Amount of payment by customer',
  changeType: 'Change Type',
  changePrice: 'Change Price',
  amountByCustomer: 'Amount By Customer',
  payedType: 'Payed Type',
  notes: "Notes:",
  changeMembershipNotes_1: '1. When switching from one package to another, the user date is changed directly.',
  changeMembershipNotes_2: '2. When changing packages, if the price is different, please make sure to enter the correct price given by the buyer in dinars or dollars.',
  "areYouSure": "Are you sure?",
  "deleteMembershipWarning": `If you delete the membership, the user's data will be deleted and the user will be back to previous membership status \n if the user not have any other membership it will be deleted`,
  membership: "Membership",
  limitationByTime: "Limitation By Time",
  deletePackage: "Delete Package",
  exportViaExcel: "Export Via Excel",
  selectColumns: "Select Columns",
  filter: "Filter",
  changeCourseTypesNote: "You only need enter in price input by dollar or iqd input by dinar don't need to fill both of them",
  addExpenseNote_1: "When you add expense from here it will be added to the expenses of the selected day, to delete or edit the expense you can go to the expenses of the day and edit or delete it",
  amountOfExpense: "Amount Of Expense",
  employee: "Employee",
  service: "Service",
  storage: "Storage",
  "return-items": "Return Items",
  productName: "Product Name",
  imageUri: "Image Uri",
  swimmingStorage: "Swimming Storage",
  items: "Items",
  receipt: "Receipt",
  usd: "USD",
  dinar: "Dinar",
  "pleaseMakeSureThisAction": "Please make sure this action !",
  "thisActionCannotBeUndone_swimming": "This action cannot be undone. This will permanently delete item. and all quantity of this item will return to the storage.",
  enName: "English Name",
  kuName: "Kurdish Name",
  arName: "Arabic Name",
  vipCourseRatio: "PT Course Ratio",
  supplementsRevenue: "Supplements Revenue",
  swimmingItemsRevenue: "Swimming Items Revenue",
  totalTrainerRatioOfSupplements: "Total Trainer Ratio Of Supplements",
  supplementsProfit: "Supplements Profit",
  totalSwimmingItemsRevenue: "Total Swimming Items Revenue",
  totalSupplementsProfit: "Total Supplements Profit",
  totalPhysicalTherapyProfit: "Total Physical Therapy Profit",
  captainName: "Captain Name",
  brandName: "Brand Name",
  brandLogo: "Brand Logo",
  nutritionStorage: "Nutrition Storage",
  "nutrition-items": "Nutrition Items",
  deleteItemDesc: "If you delete the item, the item will be deleted and item not appear on system and it will not be recoverable",
  thisActionCannotBeUndone_storage: "This action cannot be refused, the total remaining unsold quantity will be returned to storage.",
  swimmingItemsProfit: "Swimming Items Profit"
};


export default en