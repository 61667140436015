import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ArrayIndex from "../../../components/UI/AddingData/ArrayIndex";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import expensesType from "../../../data/expensesType";
import { convertCurrency } from "../../../store/actions/dollarPricesAction";
import { createExpense, deleteExpense } from "../../../store/actions/expensesAction";
import { handleLoading } from "../../../store/actions/loadingAction";
import { Button, DateInput } from "../../Users/CreateUserScreen";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import { IoTrashOutline } from "react-icons/io5";

function BigExpensesDetailsScreen() {
  const [currentPackage, setCurrentPackage] = useState(false);
  const currentUser = useSelector(state => state.authentication.user)
  const id = useParams().id;
  const { data, isLoading, error } = useSwr('/expenses/' + id, fetcher)
  const language = useTranslation().i18n.language

  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentPackage;
    if (type === 0) {
      obj.name = value;
    } else if (type === 1) {
      obj.iqd = Number(value);
    } else if (type === 2) {
      obj.centerId = value;
    } else if (type === 3) {
      obj.type = value;
    } else if (type === 4) {
      obj.date = value;
      obj.createdAt = dayjs(value).valueOf();
    }
    else if (type === 13) {
      obj['paymentDetails']['payedType'] = Number(value);
    }
    else if (type === 8) {
      obj.paymentDetails.amountByCustomer = Number(value);
    }
    else if (type === 9) {
      obj.paymentDetails.dollarAmount = Number(value);
    }
    else if (type === 10) {
      obj.paymentDetails.iqdAmount = Number(value);
    }
    else if (type === 11) {
      obj.paymentDetails.changeType = Number(value);
    }
    else if (type === 12) {
      obj.paymentDetails.changePrice = Number(value);
    }
    setCurrentPackage({ ...obj });
  };
  // console.log({currentPackage})
  const onSave = async () => {
    try {
      await checkValidation();
      handleLoading();
      await createExpense({ ...currentPackage, adminId: currentUser._id, price: convertCurrency(currentPackage.iqd, 'USD') }).catch(e => {
        handleLoading();
        throw e
      });
      history.goBack();
      handleLoading();
    } catch (e) {
      alert(e);
    }
  };

  const setUp = () => {
    let objData = {};
    let check = data[0]
    if (check) {
      objData = { ...check };
    } else {
      let obj = {
        name: null,
        iqd: null,
        centerId: "62d3de07e4b5f62ba339b22b",
        type: 3,
        paymentDetails: {
          payedType: 0,
          amountByCustomer: 0,
          changeType: 0,
          changePrice: 0
        }
      };

      objData = obj;
    }

    setCurrentPackage(objData);
  };

  const checkValidation = async () => {
    if (
      !currentPackage.name
    ) {
      throw "Invlaid Name";
    }
    if (
      !currentPackage.iqd
    ) {
      throw "Invlaid Price";
    }
    if (
      !currentPackage.date
    ) {
      throw "Invlaid Date";
    }

    if (!currentPackage.paymentDetails) {
      throw ("Please enter payment details");
    }
    if (isNaN(currentPackage.paymentDetails.payedType)) {
      throw ("Please enter payed type");
    }
    if (currentPackage.paymentDetails.payedType === 2) {
      if (isNaN(currentPackage.paymentDetails.dollarAmount)) {
        throw ("Please enter dollar amount");
      }
      if (isNaN(currentPackage.paymentDetails.iqdAmount)) {
        throw ("Please enter iqd amount");
      }
    } else {
      if (isNaN(currentPackage.paymentDetails.amountByCustomer) || currentPackage.paymentDetails.amountByCustomer === 0) {
        throw ("Please enter amount of expense");
      }
    }
  };

  useEffect(() => {
    if (data) setUp();
  }, [data]);

  const onDeleteExpense = async () => {
    try {
      const check = window.confirm(t('areYouSureYouWantToDeleteThisExpense'));
      if (check) {
        handleLoading();
        await deleteExpense(currentPackage._id);
        history.goBack();
        handleLoading();
      }
    } catch (e) {
      console.log(e);
      handleLoading();
      alert(e);
    }
  };

  if (error) return <Error />

  if (isLoading) return <Loading />
  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer onSave={onSave} customIcon={currentPackage?._id ? <IoTrashOutline color="red" onClick={onDeleteExpense} size={30} /> : null} title={t('expense')} >
        <div style={{
          textAlign: language === 'en' ? "left" : "right"
        }} className="p-6 text-sm" >
          <p>{t("notes")}</p>
          <p>{t("addExpenseNote_1")}</p>
        </div>
        {currentPackage._id && (
          <InstanceIndex label={t("idNumber")} value={`#${currentPackage._id}`} />
        )}
        <TextInput
          label={t("name")}
          placeHolder={t("name")}
          value={currentPackage.name}
          onChangeValue={onChangeValue}
          type={0}
        />
        <TextInput
          label={`${t("price") + " (IQD)"}`}
          placeHolder={t("price")}
          value={currentPackage?.iqd}
          onChangeValue={onChangeValue}
          type={1}
          isNumber
        />
        <ArrayIndex
          label={t("payedType")}
          data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
          type={13}
          valType={1}
          value={currentPackage?.paymentDetails?.payedType}
          index='_id'
          onChangeValue={onChangeValue}
        />
        {currentPackage?.paymentDetails?.payedType === 2 ? <>
          <TextInput
            label={t("dollarAmount")}
            placeHolder={t("dollarAmount")}
            value={currentPackage?.paymentDetails?.dollarAmount}
            onChangeValue={onChangeValue}
            type={9}
          />
          <TextInput
            label={t("iqdAmount")}
            placeHolder={t("iqdAmount")}
            value={currentPackage?.paymentDetails?.iqdAmount}
            onChangeValue={onChangeValue}
            type={10}
          />
        </> : <TextInput
          label={t("amountOfExpense")}
          placeHolder={t("amountOfExpense")}
          value={currentPackage?.paymentDetails?.amountByCustomer}
          onChangeValue={onChangeValue}
          type={8}
        />}

        <DateInput
          label={`${t("date")}`}
          placeHolder={t("date")}
          value={currentPackage?.date}
          onChangeValue={onChangeValue}
          type={4}
        />
        {expensesType && (
          <ArrayIndex
            label={t("type")}
            data={expensesType}
            type={3}
            valType={1}
            value={currentPackage.type}
            index='type'
            onChangeValue={onChangeValue}
          />
        )}
      </CommonInputsContainer>
    </div>
  );
}

const Container = ({ onChangeValue, currentPackage, centers }) => {
  return (
    <div className='w-4/5 mt-10 border max-h-max'>
      <InputContainer
        onChangeValue={onChangeValue}
        currentPackage={currentPackage}
        centers={centers}
      />
    </div>
  );
};

const InputContainer = ({ onChangeValue, currentPackage, centers }) => {
  const { t } = useTranslation();

  return (
    <div className='items-center justify-center bg-white'>
      {currentPackage._id && (
        <InstanceIndex label={t("idNumber")} value={`#${currentPackage._id}`} />
      )}
      <TextInput
        label={t("name")}
        placeHolder={t("name")}
        value={currentPackage.name}
        onChangeValue={onChangeValue}
        type={0}
      />
      <TextInput
        label={`${t("price") + " (IQD)"}`}
        placeHolder={t("price")}
        value={currentPackage?.iqd}
        onChangeValue={onChangeValue}
        type={1}
        isNumber
      />
      {true && (
        <>
          <ArrayIndex
            label={t("payedType")}
            data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
            type={13}
            valType={1}
            value={currentPackage?.paymentDetails?.payedType}
            index='_id'
            onChangeValue={onChangeValue}
          />
          {currentPackage?.paymentDetails?.payedType === 2 ? <>
            <TextInput
              label={t("dollarAmount")}
              placeHolder={t("dollarAmount")}
              value={currentPackage?.paymentDetails?.dollarAmount}
              onChangeValue={onChangeValue}
              type={9}
            />
            <TextInput
              label={t("iqdAmount")}
              placeHolder={t("iqdAmount")}
              value={currentPackage?.paymentDetails?.iqdAmount}
              onChangeValue={onChangeValue}
              type={10}
            />
          </> : <TextInput
            label={t("amountByCustomer")}
            placeHolder={t("amountByCustomer")}
            value={currentPackage?.paymentDetails?.amountByCustomer}
            onChangeValue={onChangeValue}
            type={8}
          />}

        </>
      )}
      <DateInput
        label={`${t("date")}`}
        placeHolder={t("date")}
        value={currentPackage?.date}
        onChangeValue={onChangeValue}
        type={4}
      />
      {expensesType && (
        <ArrayIndex
          label={t("type")}
          data={expensesType}
          type={3}
          valType={1}
          value={currentPackage.type}
          index='type'
          onChangeValue={onChangeValue}
        />
      )}
    </div>
  );
};

export default BigExpensesDetailsScreen;
